@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,400&display=swap');

* {
    box-sizing: border-box;
}

body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: 'Roboto', sans-serif;
    font-size: 10px !important ;
    margin: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

@media print {
    .hide_from_print {
        visibility: hidden;
        display: none;
    }
}

ul,
li {
    list-style: none;
    margin: 0;
    padding: 0;
}

a:link,
a:visited {
    color: inherit;
    font-size: 1.3em;
    text-decoration: none;
    text-transform: uppercase;
}

.Nav {
    padding: 8px 0;
}
.Nav ul {
    display: flex;
}
.Nav ul li {
    background-color: rgb(236, 236, 236);
    line-height: 2;
    margin: 0 5px;
    padding: 0 12px;
}

.Nav ul li:hover {
    background-color: rgb(222, 222, 222);
}

.Enter {
    height: 100vh;
    width: 100vw;
}
.Enter form {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
}

.Enter .submit_button {
    background-color: #274b62;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 3.3em;
    letter-spacing: 1px;
    line-height: 1.7;
    text-transform: uppercase;
}

.Enter .submit_button:hover {
    background-color: #1e3f53;
    cursor: pointer;
}

input.sort_filter {
    border: 1px solid rgb(202, 202, 202);
    margin-bottom: 10px;
    padding: 7px 10px;
    width: 100%;
}

input:focus.sort_filter {
    border: 1px solid #276238;
}

.Enter .submit_area {
    flex: 1;
    font-family: 'Roboto', sans-serif;
    font-size: 2.2em;
    padding: 10px;
}

.dragItem {
  background: white;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 6%), 0 1px 2px rgb(0 0 0 / 12%);
  font-size: 1.2em;
  line-height: 1.1rem;
  margin: 0 0 4px 0;
  max-width: 300px;
  padding: 10px;
  position: relative;
  width: 100%;
}

.sort_lane .dragItem.inactive {
    display: none;
}

button.close_button {
    background: none;
    border: none;
    color: rgb(149, 149, 149);
    cursor: pointer;
    font-size: 2.3em;
    margin: 0;
    padding: 5px 15px;
    position: absolute;
    right: -6px;
    top: 0;
}

button.close_button:hover {
    color: rgb(42, 42, 42);
}

h2.inactive {
    display: none;
}

h2.active {
    align-items: center;
    display: flex;
    font-weight: 700;
    font-weight: 700;
    justify-content: space-between;
}

.container_list {
    display: flex;
}

.sort_lane {
    padding-right: 10px;
    width: 220px;
}

.sort_lane .dragItem {
    background-color: aliceblue;
}

h2 + div:empty {
    background-color: rgb(246 246 246);
    border-radius: 3px;
    padding-bottom: 35px;
}

.sort_lane h2 + div:empty {
    background-color: aliceblue;
}

.targ_lane {
    column-gap: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 40px;
    width: 80%;
}

.itemdes {
    width: 160px;
}

.can_edit {
    -webkit-user-select: all;
    user-select: all;
}

.label_ref {
    align-items: center;
    border-radius: 50%;
    border: 1px solid gray;
    color: rgb(171, 169, 169);
    display: flex;
    font-size: 12px;
    height: 20px;
    justify-content: center;
    line-height: 1px;
    margin-right: 7px;
    width: 20px;
}

.Print {
    border: 0.25in solid rgb(248, 248, 248);
    height: 8.5in;
    width: 11in;
}

@media print {
    .hide_from_print {
        display: none;
        visibility: hidden;
    }
    .Print {
        border-color: white;
    }
}

.Print .date_row {
    display: flex;
    height: 100px;
    width: 100%;
}

.Print .date_row .date {
    border-bottom: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
    border-top: 1px solid #e8e8e8;
    flex-grow: 1;
    margin: 0;
    padding: 0;
    width: 1.5in;
}

.Print .date_row .date .day {
    font-size: 1.1em;
    letter-spacing: 1px;
    padding: 4px 7px;
    text-align: left;
    text-transform: uppercase;
}

.Print .date_row .date:nth-child(7) {
    border-right: 1px solid #e8e8e8;
}

.Print .date_content {
    height: 100%;
    overflow: hidden;
    padding: 0 7px 4px 7px;
}

.Print .date_content li {
    font-size: 10px;
    line-height: 13px;
    margin-bottom: 5px;
}

.Print .box_row {
    column-gap: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 1.4rem;
    row-gap: 50px;
}

.Print .box_title {
    font-size: 1.5em;
    font-weight: 500;
    line-height: 1rem;
    margin-bottom: 0.7rem;
}

.Print ul.box_events li {
    font-size: 1.1em;
    line-height: 1.4em;
    margin-bottom: 0.8em;
}


input.modal_input{
  margin-top: 20px;
  width: 100%;
  margin-bottom: 22px;
  padding: 7px 6px;
}

.login_field{
margin: 20px auto;
  width: 100%;
  max-width: 200px;

  padding: 7px 6px;
}

.Login {
  display: flex;
  
}